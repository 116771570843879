import { cn } from "@/lib/cn";

import { IconProps } from "./types/Icon";

export const SdkIcon = ({ className }: IconProps) => {
  return (
    <svg
      className={cn("block", className)}
      viewBox="0 -960 960 960"
      color="currentColor"
    >
      <path
        d="M180-120q-24.75 0-42.375-17.625T120-180v-535q0-9.8 3.5-18.55T134-750l57-74q6.457-8 14.707-12T224-840h513q10.043 0 18.293 4T770-824l57 74q5 8 9 16.654T840-715v535q0 24.75-17.625 42.375T780-120H180Zm18-615h565l-36.409-45H234l-36 45Zm-18 555h600v-495H180v495Zm460-247-70 70q-8 8.348-8 20.174Q562-325 570-317t19.826 8q11.826 0 20.174-8l89-89q9-9 9-21t-9-21l-89-89q-8.348-8-20.174-8Q578-545 570-537t-8 19.826q0 11.826 8 20.174l70 70Zm-320 4 70-70q8-8.348 8-20.174Q398-525 390-533t-19.826-8q-11.826 0-20.174 8l-89 89q-9 9-9 21t9 21l89 89q8.348 8 20.174 8Q382-305 390-313t8-19.826q0-11.826-8-20.174l-70-70ZM180-180v-495 495Z"
        fill="currentColor"
      />
    </svg>
  );
};
